@import "src/assets/styles/variables", "src/assets/styles/mixins";

.about {

  .wrapper {
    > div {
      color: $white;
      white-space: pre-line;

      @include for-tablet-portrait-up {
        width: 50%;
        margin-left: auto;
        color: $black;
      }
    }
  }
}

.background {
  div {
    background: $grey url("../../assets/images/fade-pro.jpg") no-repeat;
    height: 100vh;
    width: 100vw;
    background-blend-mode: darken;
    filter: saturate(0);

    @include for-phone-only {
      &:after {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $black;
        opacity: 0.2;

        content: '';
      }
    }
  }
}
