@import "src/assets/styles/variables", "src/assets/styles/mixins";

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}

.background {
  div {
    height: 100vh;
    width: 100vw;
    background: $grey url("../../assets/images/pompadour.jpg") right 10% bottom/650px no-repeat;
    background-blend-mode: multiply;

    @include for-phone-only {
      background: $grey url("../../assets/images/pompadour.jpg") center bottom/170% no-repeat;

      &:after {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $black;
        opacity: 0.2;

        content: '';
      }
    }
  }
}

.descriptionWrapper {
  width: calc(100% - 550px);

  h2 {
    text-transform: uppercase;
  }

  @include for-phone-only {
    width: auto;
    color: $white;
    text-align: center;

    :global(.button) {
      padding: 10px 50px;
    }

    p {
      font-family: $comfortaa-semibold-font;
      margin-bottom: 25px;
    }
  }
}
