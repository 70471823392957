@import "src/assets/styles/variables", "src/assets/styles/mixins";

header {
  top: 0;
  width: 100%;
  z-index: 1;
  position: fixed;
}

.scrolled {
  background: $black;
  color: $white;
  transition: all .1s linear;

  svg {
    fill: #fff;
  }
}

a {
  color: inherit;
}

.nav, .header, .menu {
  @extend %flex-between;
}

.header {
  padding: 15px 0;
}

.menu {
  max-width: 500px;
  margin: auto;
  padding-bottom: 15px;

  li {
    cursor: pointer;
  }

  @include for-phone-only {
    font-size: $base-font-size;
  }
}

.social {
  display: flex;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.languages {
  display: flex;

  li {
    margin-left: 10px;
    cursor: pointer;

    &.active {
      font-family: $medium-font;
    }
  }
}

.logo {
  font-family: $medium-font;
  font-size: $subheading-font-size;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }
}
