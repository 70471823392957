@import "src/assets/styles/variables", "src/assets/styles/mixins";

.contacts {
  background: $black;
  color: $white;
  text-align: right;

  @include for-phone-only {
    padding: 0;

    :global(.container) {
      padding: 0;
    }
  }
}

.wrapper {
  display: flex;
  @include for-tablet-portrait-up {
    justify-content: space-between;

    div {
      width: 50%;
    }
  }

  @include for-phone-only {
    flex-wrap: wrap;
    div {
      width: 100%;
      &:first-child {
        order: 1;
      }

      &:last-child {
        padding: 15px;
        text-align: center;
      }
    }
  }
}
