@import "src/assets/styles/variables", "src/assets/styles/mixins";

.team {
  text-align: center;
}

.teamList {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  figure {
    @include for-phone-only {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  img {
    width: 500px;
    height: 500px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
    margin-bottom: 10px;
    filter: saturate(.5);

    @include for-phone-only {
      width: 345px;
      height: 345px;
    }
  }

  figcaption {
    font-size: $base-font-size;
  }
}
