@import "src/assets/styles/variables", "src/assets/styles/mixins";

.services {
  text-align: center;
}

.wrapper {
  display: flex;
  justify-content: space-around;

  ul {
    width: 265px;
  }

  @include for-phone-only {
    flex-wrap: wrap;
    font-size: $base-font-size;

    ul {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
