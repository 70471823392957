@import "fonts";

$regular-font: "Comfortaa Regular", sans-serif;
$comfortaa-semibold-font: "Comfortaa SemiBold", sans-serif;
$montserrat-medium-font: "Montserrat Medium", sans-serif;
$montserrat-font: "Montserrat Regular", sans-serif;
$medium-font: "Inter Medium", sans-serif;
$bold-font: "Inter Bold", sans-serif;

$base-font-size: 16px;
$mobile-font-size: 13px;
$base-line-height: 1.4;

$tiny-font-size: 0.7143rem;
$heading-font-size: 2.375rem;
$subheading-font-size: 1.71rem;

$white: #ffffff;
$grey: #ececec;
$grey-blue: #7f87b6;
$grey-blue10: rgba(127, 135, 182, 0.1);
$grey-blue50: rgba(127, 135, 182, 0.5);
$black: #111111;
$dark-blue: #143980;
$primary: #569de5;
$primary30: rgba(86, 157, 229, .3);
$primary50: rgba(86, 157, 229, .5);
$primary-hover: #3a89da;
$primary-active: #2f80ed;
$secondary: #bdbdbd;
$secondary-hover: #b5b5b5;
$secondary-active: #818181;
$success: #27ae60;
$success10: rgba(39, 174, 96, .1);
$success50: rgba(39, 174, 96, .5);
$success-hover: #219653;
$success-active: #138e48;
$warning: #f2994a;
$warning-hover: #f18a2e;
$warning-active: #fa7500;
$danger: #eb5757;
$danger50: rgba(235, 87, 87, .5);
$danger-hover: #e84444;
$danger-active: #cf2020;
$text-muted: rgba(17, 17, 17, .5);
$modal-backdrop: rgba(17, 17, 17, .3);

$base-radius: 4px;
$base-shadow: 0 4px 16px 0 rgba(93, 103, 160, 0.08), 0 4px 4px 0 rgba(93, 103, 160, 0.15);
$input-shadow-hover: 0 4px 20px 0 rgba(93, 103, 160, 0.08), 0 4px 20px 0 rgba(93, 103, 160, 0.15);

$max-index: 10;

%flex {
  display: flex;
  align-items: center;
}

%flex-center {
  @extend %flex;
  justify-content: center;
}

%flex-between {
  @extend %flex;
  justify-content: space-between;
}
