@font-face {
  font-family: 'Inter Regular';
  font-display: swap;
  src: local('Inter Regular'), local('Inter-Regular'),
  url('../fonts/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter Medium';
  font-display: swap;
  src: local('Inter Medium'), local('Inter-Medium'),
  url('../fonts/Inter-Medium.ttf');
}

@font-face {
  font-family: 'Inter Bold';
  font-display: swap;
  src: local('Inter Bold'), local('Inter-Bold'),
  url('../fonts/Inter-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat Regular';
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Comfortaa SemiBold';
  font-display: swap;
  src: local('Comfortaa SemiBold'), local('Comfortaa-SemiBold'),
  url('../fonts/Comfortaa-SemiBold.ttf');
}

@font-face {
  font-family: 'Comfortaa Regular';
  font-display: swap;
  src: local('Comfortaa Regular'), local('Comfortaa-Regular'),
  url('../fonts/Comfortaa-Regular.ttf');
}

