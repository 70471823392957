@import "assets/styles/reset", "assets/styles/variables.scss", "assets/styles/mixins.scss";

html {
  font-size: $base-font-size;
  scroll-behavior: smooth;

  @include for-phone-only {
    font-size: $mobile-font-size;
  }
}

body {
  margin: 0;
  font-family: $regular-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: $base-line-height;
}

h1 {
  font-size: $heading-font-size;
}

h2 {
  font-size: $subheading-font-size;
}

h1, h2, h3 {
  font-family: $montserrat-font;
  letter-spacing: -.8px;
  margin-bottom: 1rem;
  line-height: 1.1;
  font-weight: unset;
}

p {
  margin-bottom: .75rem;
  font-size: 1.25rem;

  @include for-phone-only {
    font-family: $comfortaa-semibold-font;
  }
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

.button {
  border: 1px solid $black;
  color: $black;
  min-width: 140px;
  padding: 7px 21px;
  line-height: $base-line-height;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  font-family: $bold-font;
  font-size: .8em;
  letter-spacing: 2px;
  transition: all .1s ease-out;

  &.primary {
    background-color: transparent;

    &:hover {
      background-color: $black;
      color: $white;
    }

    @include for-phone-only {
      color: $white;
      border-color: $white;
      font-size: 1rem;

      &:hover {
        background-color: $white;
        color: $black;
      }
    }
  }

  &.secondary {
    background-color: $secondary;

    &:hover {
      background-color: $secondary-hover;
    }

    &:active {
      background-color: $secondary-active;
    }

    &:focus {
      border-color: $secondary-active;
    }
  }

  &.success {
    background-color: $success;

    &:hover {
      background-color: $success-hover;
    }

    &:active {
      background-color: $success-active;
    }

    &:focus {
      border-color: $success-active;
    }
  }

  &.danger {
    background-color: $danger;

    &:hover {
      background-color: $danger-hover;
    }

    &:active {
      background-color: $danger-active;
    }

    &:focus {
      border-color: $danger-active;
    }
  }

  &.warning {
    background-color: $warning;

    &:hover {
      background-color: $warning-hover;
    }

    &:active {
      background-color: $warning-active;
    }

    &:focus {
      border-color: $warning-active;
    }
  }

  &.small {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  &.round {
    min-width: 44px;
    min-height: 44px;
    border-radius: 50%;
    padding: 0;

    i {
      font-size: 24px;
      vertical-align: middle;
    }
  }
}

input, select {
  font-size: $base-font-size;
}

.input, .textarea {
  background-color: $white;
  border-radius: $base-radius;
  line-height: $base-line-height;
  padding: 8px 16px;
  border: none;
  box-shadow: $base-shadow;

  &:hover {
    box-shadow: $input-shadow-hover;
  }

  &:active, &:focus {
    box-shadow: $base-shadow;
  }

  &.error {
    background: $danger50;
  }
}

.checkbox {
  appearance: none;
  display: inline-block;
  position: relative;
  min-height: 18px;
  min-width: 18px;
  border: 1px solid $primary;
  border-radius: $base-radius;
  cursor: pointer;
  outline: none;
  background: $white;

  &:hover {
    border-color: $primary-hover;
  }

  &:checked,
  &.checked {
    background: $primary;

    &::before {
      position: absolute;
      display: block;
      left: 4px;
      top: 7px;
      transform: rotate(-50deg);
      height: 8px;
      width: 2px;
      border-radius: 1px;
      background: $white;
      content: "";
    }

    &::after {
      position: absolute;
      display: block;
      left: 10px;
      top: 2px;
      transform: rotate(35deg);
      height: 12px;
      width: 2px;
      border-radius: 1px;
      background: $white;
      content: "";
    }
  }
}

.radio {
  appearance: none;
  position: relative;
  min-height: 18px;
  min-width: 18px;
  border: 1px solid $primary;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  background: $white;

  &:hover {
    border-color: $primary-hover;
  }

  &:checked,
  &.checked {
    background: $primary;

    &::before {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: $white;
      content: "";
    }
  }
}

.toggle {
  appearance: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
  text-indent: -9999px;
  width: 200px;
  height: 100px;
  background: grey;
  border-radius: 100px;

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 90px;
    height: 90px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  &:checked {
    background: #bada55;
  }

  &:checked:before {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  &:active:before {
    width: 130px;
  }
}

[disabled], .disabled {
  opacity: .5;
  pointer-events: none;
}

.w100 {
  width: 100%;
}

.isLoading {
  overflow: hidden;
  position: relative;

  &:before {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $modal-backdrop;
    z-index: 2;

    content: '';
  }

  &:after {
    width: 50px;
    height: 50px;
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    animation: spinning 1s infinite;
    z-index: 3;

    content: ''
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
}


section {
  padding: 40px 0;
}

.button-container {
  position: fixed;
  right: -500px;
  bottom: 10px;
  transition: all .2s linear;
  background: rgba(0 ,0, 0, 0.2);
  padding: 10px;

  .button {
    color: #ffffff;
    border-color: #ffffff;
  }

  &.showed {
    right: 10px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 30;

  .warning {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: $black;
    color: $white;
    padding: 15px;
    margin: 0 15px;
    border-radius: 8px;
    text-align: center;

    button {
      color: $white;
      border-color: $white;
    }
  }
}
